<template>
    <div class="project-item">
        <div class="text">
            
            <h2>
                <router-link :to="projectLink(this.projectdata)" class="title">{{ this.projectdata.attributes.title }}</router-link>
            </h2>

            <div class="summary" >
                <p v-html="projectdata.attributes.field_project_description.summary"></p>
            </div>

            <div>
            <br>
            <router-link :to="projectLink(this.projectdata)" class="cta-link">
                <span>Read More</span>
            </router-link>
        </div>
        </div>
        <div class="image">
            <img :src="imgsource()" alt="">
        </div>
    </div>
</template>

<script>
  import {
    detailsLink
  } from '../libs/utils'

export default {
    name: 'single-project',
    data: () => {
        return {
        }
    },
    props: {
        projectdata: Object
    },
    computed: {
    },
    methods: {
        imgsource() {
            //console.log('this.projectdata',this.projectdata);
            //console.log(this.projectdata.field_project_image)
            if(this.projectdata.field_project_image)
                return process.env.VUE_APP_ENDPOINT + this.projectdata.field_project_image.attributes.uri.url
            else 
                return ''
        },
        projectLink(n) {
            return detailsLink('project', n)
        }
    },
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";

.project-item {
    border: 1px solid #F0F0F0;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    overflow: hidden;
    border-radius: 0px 0px 40px 0px;
    min-height: 400px;
    margin-bottom: 40px;

    background-image: url("../assets/filigrana-projects.png");
    background-position: bottom right;
    background-size: 30%;
    background-repeat: no-repeat;

    .text {
        padding: 2rem 6rem 2rem 4rem;
        height: 100%;
        width: 60%;
    }

    .image {
        width: 40%;
        min-height: 400px;
        display: -webkit-flex;
        align-items: flex-start;
        justify-content: flex-start;
        &:after {
            position: absolute;
            display: block;
            width: 22px;
            height:22px;
            content: "";
            top: 20px;
            right: 20px;
            border-style: solid;
            border-color: #FDDB40;
            border-width: 7px 7px 0px 0px;
        }
        &:before{
            content: "";
            background-image: url("../assets/pattern-overlay-project.png");
            width: 100%;
            height: 100%;
            z-index: 0;
            top: 0;
            right: 0px;
            display: block;
            position: absolute;
        }
        img {
            width: 100%;
            object-fit: cover;
            border-bottom-right-radius: 50px;
        }
    }
    h2 {
        font-size: 2.75rem;
        color: $secondaryColor;
        margin-bottom: 20px;

        a {
            text-decoration: none;
            line-height: 1.9rem;
        }
    }
    p {
        font-size: 1.125rem;
        line-height: 1.9rem;
        font-weight: 500;
        color: #333333;
    }
    .summary  {
        font-size: 1.5rem;
        color: #333333;
        line-height: 2.2rem;
        font-weight: 400;
        margin:  0rem;
    }
}

@media (max-width:1400px) {
    .project-item {
        .text {
            width: 50%;
        }
        .image {
            width: 50%;
        }
    }
    .summary p  {
        font-size: 1.125rem;
        line-height: 1.9rem;
    }
}

@media (max-width:1200px) {
    .project-item {
        .text {   
            width: 60%;
            padding: 2rem 3rem 2rem 3rem;
        }
        .image {
            width: 40%;
        }
    }
}

@media (max-width:992px) {
    .project-item {
        display: flex; display: -webkit-flex;
        flex-direction: column-reverse;
        height: auto;
        min-height: 400px;
        .text {
            background-size: 50%;
            width: 100%;
        }
        .image {
            width: 100%;
            height: 100%;
            max-height: 400px;
            min-height: unset;
            border-bottom-right-radius: 50px;
            img {
                min-height: unset;
                width: 100%;
                height: 100%;
            }
        }
        h2 {
            font-size: 2.25rem;
        
        
        }
    }
    .summary p  {
        font-size: 1rem;
        line-height: 1.9rem;
    }
}

@media (max-width: 768px) {
    .project-item .text {
        padding: 2rem 2rem ;
    }
}

@media (max-width: 576px) {
    .project-item {
        .text {
            padding: 2rem 1rem;
        }
        h2 {
            font-size: 1.875rem;
        }   
        p.summary {
            font-size: 1.25rem;
            line-height: 1.8rem;
            margin: 0rem 0rem 1rem;
        }
    }

}
</style>