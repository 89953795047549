<template>
    <div class="template-page projects">
        <basic-page :fulldata="fulldata" :pagedata="this.pageData" :bannerimg="this.bannerimg">
             <div class="container">
                <single-project 
                    v-for="(p, index) in projectData" :key="'project'+index"
                    :projectdata="p">
                </single-project>
            </div>
        </basic-page>
       
    </div>
</template>

<script>
import { fetchNodes, fetchSingleNode } from '../libs/drupalClient'
import BasicPage from './BasicPage.vue'
import SingleProject from '../components/SingleProject.vue'

export default {
  components: { BasicPage, SingleProject },
    name: 'projects',
    data: () => {
        return {
            currentPageId: "667a0e98-45f9-40e4-9f2b-3256918a8a81",
            pageData: {},
            fulldata: {},
            projectData: {},
            bannerimg: null
        }
    },
    methods: {
    },
    computed: {

    },
    mounted() {
        fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top','field_page_image', 'field_attachments'] })
            .then(res => {
                this.pageData = res[0].attributes
                this.fulldata = res[0]
                if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })
        fetchNodes('project', {
            include: ['field_project_image']
        }).then(res => {
            this.projectData = res.sort((a,b) => { return a.attributes.field_weight > b.attributes.field_weight ? 1 : -1 })
            //console.log('projects', this.projectData)
        })
    }
}
</script>

<style lang="scss" scoped>

</style>